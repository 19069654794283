<template>
  <article-page :menu="menu">
    <div class="training-content" id="section-fast-search">
      <div class="_subtitle">
        Быстрый поиск
      </div>
      <span>
        В системе предусмотрено 3 режима поиска - быстрый поиск, расширенный поиск и поиск по шаблонам.
        В этой статье будут рассмотрены первые два режима поиска. Про поиск по шаблонам читайте
        <accessed-link :name="Tabs.Training.Templates">здесь</accessed-link>.
      </span>
      <span>
        В быстром поиске доступны 4 фильтра - фильтры по словам, отраслям, регионам и законам.
      </span>
      <alert-message full-info type="warning">
        <template #content>
          Информация о том, как работают перечисленные фильтры, будет немного ниже.
        </template>
      </alert-message>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
      <span>
        Результаты поиска содержат информацию о количестве найденных тендеров и их примерной сумме.
      </span>
      <span>
        Карточки тендеров максимально упрощены, в них содержится основная информация о тендере,
        а так же два интерактивных элемента - кнопки "Добавить в избранное"/"Заявка создана" и "Подробней о закупке".
      </span>
      <ul>
        <li>Кнопка "Подробней о закупке" ведет на подробную карту лота;</li>
        <li>Кнопка "Добавить в избранное" добавляет тендер на вкладку "Заявки", путем назначения на тендер статуса "Расчет заявки (избранное)".</li>
      </ul>
      <span>
        Подробнее про работу с заявками можно прочитать
        <accessed-link :name="Tabs.Training.Apps">здесь</accessed-link>.
      </span>
      <span>
        Вам так же доступна панель дофильтрации результатов. В ней можно установить дополнительные фильтры по цене, дате публикации и дате окончания подачи заявки,
        установить сортировку или скачать excel-файл с результатами поиска.
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt" @click="openViewer(images, 1)"/>
    </div>
    <div class="training-content" id="section-advanced-filters">
      <div class="_subtitle">
        Расширенный поиск
      </div>
      <span>
        Расширенный режим позволяет искать тендеры по таким критериям, как:
      </span>
      <ul>
        <li>Ключевые слова и слова исключения;</li>
        <li>Отрасли;</li>
        <li>Регионы;</li>
        <li>Законы;</li>
        <li>Площадки;</li>
        <li>Поиск по документации;</li>
        <li>НМЦ (Начальная цена);</li>
        <li>Типы и этапы закупок;</li>
        <li>ЗМО (Закупки малого объема);</li>
        <li>СМП и СОНКО;</li>
        <li>Дата публикации и дата окончания подачи заявок;</li>
        <li>Категории ОКПД;</li>
        <li>Место выполнения работ (оказания услуг, поставки товара);</li>
        <li>Заказчики;</li>
        <li>Участники;</li>
      </ul>
      <span>
        Все фильтры поделены на группы, для переключения между ними используйте навигацию между вкладками.
        Для удобства, рядом с названием вкладки выводится количество установленных на ней фильтров.
      </span>
      <span>
        Дополнительно, у фильтров по отраслям и поиску по документации стоит модификатор И-ИЛИ. Он работает следующим образом:
      </span>
      <ul>
        <li>Модификатор у фильтра по <b>отрасли</b> -  поиск будет осуществляться по ключевым словам И/ИЛИ по выбранным отраслям.</li>
        <li>Модификатор у фильтра по <b>документации</b> -  поиск будет осуществляться по (ключевым словам И/ИЛИ по документации) И/ИЛИ по отраслям.</li>
      </ul>
      <article-image :src="images[2].src" :alt="images[2].alt" @click="openViewer(images, 2)"/>
      <span>
        В отличие от быстрого поиска, в режиме расширенного поиска карточки найденных тендеров содержат больше интерактивных элементов.
        В карточке тендера можно
        оставить <accessed-link :name="Tabs.Training.Comments">комментарий</accessed-link>,
        перевести <accessed-link :name="Tabs.Training.Apps">статус</accessed-link>,
        назначить <accessed-link :name="Tabs.Training.Tags">тег</accessed-link>,
        заказать <accessed-link :name="Tabs.Training.TenderServices">тендерные услуги</accessed-link>,
        а так же выделять несколько закупок и работать с ними через <accessed-link :name="Tabs.Training.MultiCardActions">панель групповых действий</accessed-link>.
      </span>
      <article-image :src="images[3].src" :alt="images[3].alt" @click="openViewer(images, 3)"/>
      <alert-message full-info type="warning">
        <template #content>
          На этом информация о том, чем отличается быстрый и расширенный режимы поиска закончена. Ниже будет описано, как работать с некоторыми фильтрами.
        </template>
      </alert-message>
    </div>
    <div class="training-content" id="section-keywords">
      <div class="_subtitle">
        Поиск по ключевым словам
      </div>
      <span>
        Рассмотрим подробнее, как работает поиск по ключевым словам.
      </span>
      <span>
        Для того, чтобы обозначить границы введенной фразы, нажмите enter.
        Введенная фраза обозначится тегом, который можно удалить, нажав на крестик. После этого Вы можете продолжить ввод ключевых слов/фраз.
        Обратите внимание, что теги можно редактировать.
      </span>
      <span>
        Разные теги ищутся через логический оператор ИЛИ, соответственно в результате поиска Вы увидите закупки,
        в которых введенные теги встречаются как минимум единожды.
        Если в теге написано несколько слов через пробел, то они ищутся через логический оператор И - такие слова должны присутствовать все,
        однако не обязательно друг за другом или в заданном порядке.
      </span>
      <span>
        Фильтр по словам исключениям работает аналогично. Можно вводить по одному или несколько слов.
        Система отфильтрует все найденные закупки, исключив при этом те, в которых будут найдены введенные слова.
      </span>
      <article-image :src="images[4].src" :alt="images[4].alt" width="560px" @click="openViewer(images, 4)"/>
    </div>
    <div class="training-content" id="section-expert-search">
      <div class="_subtitle">
        Построение профессиональных запросов
      </div>
      <span>
        В ситуациях, когда результаты обычного поискового запроса не удовлетворительны, вы можете использовать <b class="normal">профессиональные запросы</b>.
      </span>
      <alert-message full-info>
        <template #content>
          Обратите внимание, при наличие профессионального запроса, ключевые слова, слова исключения, а так же поиск по документации <b class="normal">игнорируются</b> поисковой системой.
          В отличие от других расширенных фильтров, поиск по словам можно использовать только в одном из двух режимов - стандартный или профессиональный.
        </template>
      </alert-message>
      <article-image :src="images[5].src" :alt="images[5].alt" width="560px" @click="openViewer(images, 5)"/>
      <template v-for="t of tables">
        <span class="margin"> {{ t.name }} </span>
        <table class="expert-search-table">
          <colgroup>
            <col span="1" style="width: 25%" />
            <col span="1" style="width: 75%" />
          </colgroup>
          <tr>
            <th> Оператор </th>
            <th> Описание </th>
          </tr>
          <tr v-for="item of t.table">
            <td> {{ item.label }} </td>
            <td> {{ item.description }} </td>
          </tr>
        </table>
      </template>
      <span class="margin">
        Поисковым запросом может выступать любое слово, фраза, часть слова, номер закупки и т.д. Также запросы можно комбинировать, рассмотрим немного примеров:
      </span>
      <span class="example-number">
        Пример 1
      </span>
      <span>
        Начнем с простых операторов. Запрос <b> молоко & хлеб </b> найдет все закупки, где встречаются слова <b>молоко</b> И <b>хлеб</b>,
        также будут учтены однокоренные слова. Запрос <b> молоко | хлеб </b> найдет все закупки, где встречается хотя бы одно слово - <b>молоко</b> ИЛИ <b>хлеб</b>.
      </span>
      <span>
        В случае, когда необходимо найти слова <b>медицина</b> и <b>медицинский</b>, запрос будет выглядеть так: <b>медици:*</b>.
        Рекомендуем в качестве префикса не использовать самостоятельные слова, так как это может повлиять на поисковую выдачу. Поэтому лучше использовать запрос вида <b>назначе:*</b> вместо <b>назначеН:*</b>.
      </span>
      <span class="example-number">
        Пример 2
      </span>
      <span>
        В случае, когда необходимо искать только по наименованию закупки, запрос можно составить следующим образом: <b>молоко:A & хлеб:*AB</b>.
        Мы указываем, что слово молоко мы ищем в наименовании закупки, а слово хлеб должно присутствовать в наименовании или в объектах закупки.
      </span>
      <span class="example-number">
        Пример 3
      </span>
      <span>
        Вы можете расширить либо ограничить поисковый запрос кодами ОКПД: <b>(молоко:A | хлеб:*AB) & (56.29:*C)</b>.
        Таким образом, слово молоко ищется в наименовании, слово хлеб в наименовании или объектах закупки (в наименовании также будут найдены слова, начинающиеся с префикса хлеб), а код ОКПД в своей области.
        В этом примере найдутся закупки, содержащие коды ОКПД, начинающиеся с 56.29, а также ключевые слова молоко ИЛИ хлеб.
      </span>
      <span class="example-number">
        Пример 4
      </span>
      <span>
        Вы можете отфильтровать нежелательные слова, для этого дополните их списком в запрос, используя оператор отрицания <b>!</b>:
      </span>
      <span>
        <b>( (молоко:A | хлеб:*AB) & (56.29:*C) ) & !(кефир:* | ряженка)</b>
      </span>
      <span class="example-number">
        Пример 5
      </span>
      <span>
        Число N в операторе &lt;N&gt; отвечает за то, каким по счету будет правое слово от левого. Увеличивая число N, можно находить различные комбинации фраз.
        Предлоги, частицы и союзы не учитываются.
        <b> {{ 'ремонт<2>территории' }} </b> - в этом примере подразумевается фраза ремонт * территории, где вместо * может быть любое ОДНО слово (одно, так как правое слово в таком случае будет ВТОРЫМ от левого).
        Поисковая выдача может содержать фразы "...ремонт <i>дворовой</i> территории...", "...по ремонту <i>ограждения</i> территории..." и т.д.
      </span>
      <alert-message full-info type="warning" style="margin-top: 12px;">
        <template #content>
          Наша компания предоставляет услуги настройки профессиональных шаблонов по Вашим критериям и требованиям к поиску.
          <a :href="MailAddress.orderLink" class="connect-link"> Свяжитесь с нами </a> для получения бесплатной консультации по оказываемым услугам.
        </template>
      </alert-message>
    </div>
    <div class="training-content" id="section-docs-search">
      <div class="_subtitle">
        Поиск по документации
      </div>
      <span>
        Поиск по документации имеет некоторые <b>ограничения</b>:
      </span>
      <ul>
        <li>Поиск по документации поддерживает только режим поиска по ключевым словам. Профессиональный поиск по документации не предусмотрен системой.</li>
        <li>Поиск происходит только среди заявок, которые на момент поиска имеют статус "подача заявок" или "работа комиссии";</li>
        <li>Из предыдущего пункта следует, что поиск по документации недоступен на вкладках Аналитики;</li>
        <li>
          Поиск по документации только по словам исключениям <b>не поддерживается</b>.
          Если указано объединение результатов поиска по ключевым словам и документам, то слова исключения можно указывать только при заполнении ключевых слов поиска/документации.
          Если указано пересечение результатов, то слова исключения можно указывать только при заполнении ключевых слов документации.
        </li>
      </ul>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import { MailAddress } from "@/const/contacts";
import AccessedLink from "~/components/ui/links/AccessedLink.vue";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "FiltersArticle",
  components: {
    AccessedLink,
    ArticleImage,
    AlertMessage,
    ArticlePage,
  },
  setup() {

    const menu = [
      { id: 'section-fast-search', title: 'Быстрый поиск' },
      { id: 'section-advanced-filters', title: 'Расширенный поиск' },
      { id: 'section-keywords', title: 'Поиск по ключевым словам' },
      { id: 'section-expert-search', title: 'Профессиональные запросы' },
      { id: 'section-docs-search', title: 'Поиск по документации' },
    ]

    const images = [
      { src: 'filters/fast-search-full.png', alt: 'Быстрый поиск' },
      { src: 'filters/fast-search-results.png', alt: 'Результаты быстрого поиска' },
      { src: 'filters/active-filters.png', alt: 'Расширенный поиск' },
      { src: 'filters/search-results.png', alt: 'Результаты расширенного поиска' },
      { src: 'filters/keywords-mode.png', alt: 'Поиск по ключевым словам' },
      { src: 'filters/expert-mode.png', alt: 'Профессиональный поиск' },
    ]

    const operatorTable = [
      { label: '&', description: 'Логическое И' },
      { label: '|', description: 'Логическое ИЛИ' },
      { label: '!', description: 'Логическое НЕ' },
      { label: '()', description: 'Группировка' },
      { label: '*', description: 'ПРЕФИКС (ключевое слово из запроса является началом искомого слова)' },
      { label: ':', description: 'ПЕРЕЧИСЛЕНИЕ (используется только для указания областей поиска или оператора ПРЕФИКС)' },
      { label: '<N>', description: 'ФРАЗА (используется для поиска слов с заданным удалением (N) друг от друга.)' },
    ]

    const searchAreaTable = [
      { label: 'A (латинская буква)', description: 'Наименование закупки' },
      { label: 'B (латинская буква)', description: 'Объекты закупки' },
      { label: 'C (латинская буква)', description: 'Коды ОКПД' },
      { label: 'D (латинская буква)', description: 'Место выполнения работ (оказания услуг, поставки товара)' },
    ]

    const tables = [
      { name: 'Операторы:', table: operatorTable },
      { name: 'Области поиска:', table: searchAreaTable },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      tables,
      images,
      Tabs,
      openViewer,
      MailAddress,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';

.margin {
  margin-top: 24px;
}

.connect-link {
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: var(--default-blue-color);
  }
}

#section-expert-search {

  .example-number {
    font-weight: bold;
  }

  b:not(.normal) {
    color: #409eff;
    background-color: #ecf5ff;
    border-radius: 4px;
    padding: 2px 4px;
  }
}

.expert-search-table {
  font-size: 14px;
  border-spacing: 0;

  tr {
    vertical-align: top;
  }

  th {
    text-align: start;
    background-color: #f5f7fa;
  }

  td, th {
    padding: 8px 8px;
    border-bottom: 1px solid #ebeef5;
  }
}
</style>
